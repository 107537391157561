<template>
    <div>
		<div class="header-content">
			<h2 class="cs-title" v-if="data.title">
				<ColorSplit :data="data.title" />
			</h2>
			<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
			<span class="cs-text" v-if="data.text" v-html="data.text"></span>
		</div>
		
		<PostCards :posts="data.posts" :readMoreLabel="data.readMoreLabel" :limit="true" />
	</div>
</template>

<script>

export default {
	name: "CSBlogPostPreview",
	components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        PostCards: () => import("../components/CSUtilsPostCards.vue")
    },
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			cards: [],
		}
	},
	methods: {
		cardShortText(title, maxLength) {
			const shortenedText =
				title.substring(0, maxLength) + (title.length > maxLength ? "..." : "")
			return shortenedText
		},
		convertDate() {
			const date = new Date()
			let day = date.getDate()
			if (day < 10) day = `0${day}`
			let month = date.getMonth() + 1
			if (month < 10) month = `0${month}`

			const year = date.getFullYear()
			return `${day}.${month}.${year}`
		},
	},
	mounted() {
		console.log(this)
	},
}
</script>

<style lang="scss" scoped>
.header-content {
	text-align: center;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	.cs-title, .cs-sub-title {
		margin-bottom: 20px;
	}
	.cs-text {
		::v-deep {
			* {
				text-align: center;
			}
			img {
				max-width: 100%;
				height: 300px;
				border-radius: 10px;
				margin: 10px;
			}
			*:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.cs-blog-post-preview {
	&-list {
		row-gap: 30px;
	}
	&-card {
		border: 1px solid black;
		border-radius: 20px;
		flex: 1 1 auto;
		overflow: hidden;
		img {
			width: 100%;
			border-top-left-radius: 20px !important;
			border-top-right-radius: 20px !important;
			height: 180px;
			object-fit: cover;
			object-position: contain;
		}

		&-info {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 15px;
			.button {
				width: 100%;
				padding: 15px 0;
				a {
					padding: 10px 20px;
					text-decoration: none;
				}
			}
		}

		&-title {
			width: 100%;
			font-size: 15px;
			font-family: Italiana, sans-serif;
			color: #333333;
			margin-bottom: 5px;
			@media (max-width: 991px) {
				font-size: 20px;
			}
		}

		&-text {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}

.author-info {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 20px;
	.author,
	.date {
		color: #000;
		font-size: 12px;
	}
}

a {
	text-decoration: none;
}
</style>
